import type { ReactNode } from 'react';

import type { SwipeableDrawerProps } from '@mui/material';
import { Box, SwipeableDrawer } from '@mui/material';

const BottomSheet = ({
  children,
  ...props
}: {
  children: ReactNode;
} & SwipeableDrawerProps) => (
  <SwipeableDrawer
    anchor="bottom"
    disableSwipeToOpen={true}
    PaperProps={{
      sx: {
        display: 'flex',
        p: '8px 16px 24px',
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
      },
    }}
    {...props}
    sx={{
      zIndex: 1300,
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '44px',
          height: '4px',
          bgcolor: 'gray.200',
          borderRadius: '4px',
          mb: '28px',
        }}
      />
    </Box>
    {children}
  </SwipeableDrawer>
);

export default BottomSheet;
