import { useCallback, useMemo } from 'react';

import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import { enqueueSnackbar } from 'notistack';

import useActiveAuth from 'hooks/useActiveAuth';
import useManagerAuth from 'hooks/useManagerAuth';

import { authAxios } from 'lib/axios';

const PERMISSION_DENIED_MESSAGE = 'channel permission denied';

const useAxios = () => {
  const { clearManagedContext, token, role } = useActiveAuth();
  const {
    user: { id: managedChannelUid },
  } = useManagerAuth();
  const router = useRouter();
  const { t } = useTranslation('common');

  const errorInterceptor = useCallback(
    (error: string) => {
      if (role !== 'owner' && error === PERMISSION_DENIED_MESSAGE) {
        clearManagedContext();
        router.push('/');
        enqueueSnackbar(t('managerPermissionDeleted'), { variant: 'error' });
      }

      return Promise.reject(error);
    },
    [clearManagedContext, role, router, t],
  );

  const baseAuthAxiosConfig = useMemo(
    () => ({ token, channelUid: managedChannelUid }),
    [managedChannelUid, token],
  );

  //TODO: interceptor 추가
  const liveAuthAxiosV1 = useMemo(() => {
    const instance = authAxios({
      serverType: 'live',
      version: 'v1',
      token,
    });

    return instance;
  }, [token]);

  const studioPublicAxiosV1withToken = useMemo(() => {
    const instance = authAxios({
      serverType: 'studio-public',
      version: 'v1',
      ...baseAuthAxiosConfig,
    });

    instance.interceptors.response.use(
      (response) => response,
      errorInterceptor,
    );

    return instance;
  }, [baseAuthAxiosConfig, errorInterceptor]);

  const studioAuthAxiosV1 = useMemo(() => {
    const instance = authAxios({
      serverType: 'studio',
      version: 'v1',
      ...baseAuthAxiosConfig,
    });

    instance.interceptors.response.use(
      (response) => response,
      errorInterceptor,
    );

    return instance;
  }, [baseAuthAxiosConfig, errorInterceptor]);

  const studioAuthAxiosV2 = useMemo(() => {
    const instance = authAxios({
      serverType: 'studio',
      version: 'v2',
      ...baseAuthAxiosConfig,
    });

    instance.interceptors.response.use(
      (response) => response,
      errorInterceptor,
    );

    return instance;
  }, [baseAuthAxiosConfig, errorInterceptor]);

  const studioAuthAxiosAi = useMemo(() => {
    const instance = authAxios({
      serverType: 'studio-ai',
      version: 'v1',
      ...baseAuthAxiosConfig,
    });

    instance.interceptors.response.use(
      (response) => response,
      errorInterceptor,
    );

    return instance;
  }, [baseAuthAxiosConfig, errorInterceptor]);

  return {
    liveAuthAxiosV1,
    studioPublicAxiosV1withToken,
    studioAuthAxiosV1,
    studioAuthAxiosV2,
    studioAuthAxiosAi,
  };
};

export default useAxios;
