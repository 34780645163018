import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const ListSortIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M9.23673 17.357H2.6051C1.94194 17.357 1.5 16.9286 1.5 16.2857C1.5 15.6429 1.94194 15.2145 2.6051 15.2145H9.23673C9.8999 15.2145 10.3418 15.6429 10.3418 16.2857C10.3418 16.9286 9.8999 17.357 9.23673 17.357Z" />
    <path d="M12.5526 11.9997H2.6051C1.94194 11.9997 1.5 11.5713 1.5 10.9285C1.5 10.2856 1.94194 9.85725 2.6051 9.85725H12.5526C13.2157 9.85725 13.6577 10.2856 13.6577 10.9285C13.6577 11.5713 13.2157 11.9997 12.5526 11.9997Z" />
    <path d="M18.0791 19.5C17.4159 19.5 16.974 19.0716 16.974 18.4288V9.85725C16.974 9.2144 17.4159 8.786 18.0791 8.786C18.7422 8.786 19.1842 9.2144 19.1842 9.85725V18.4288C19.1842 19.0716 18.7422 19.5 18.0791 19.5Z" />
    <path d="M18.0791 19.5C17.7475 19.5 17.5263 19.393 17.3056 19.1786L13.9897 15.9643C13.5478 15.5359 13.5478 14.8931 13.9897 14.4642C14.4317 14.0353 15.0948 14.0358 15.5373 14.4642L18.0796 16.9286L20.6219 14.4642C21.0638 14.0358 21.727 14.0358 22.1695 14.4642C22.6119 14.8926 22.6114 15.5354 22.1695 15.9643L18.8536 19.1786C18.6324 19.393 18.4117 19.5 18.0801 19.5L18.0791 19.5Z" />
    <path d="M15.8684 6.643H2.6051C1.94194 6.643 1.5 6.2141 1.5 5.57125C1.5 4.9284 1.94194 4.5 2.6051 4.5H15.8684C16.5315 4.5 16.9735 4.9284 16.9735 5.57125C16.9735 6.2141 16.5315 6.643 15.8684 6.643Z" />
  </SvgIcon>
);

export default ListSortIcon;
