import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const LayoutIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.917969 3.99984C0.917969 2.15889 2.41035 0.666504 4.2513 0.666504H14.2513C16.0923 0.666504 17.5846 2.15889 17.5846 3.99984V13.9998C17.5846 15.8408 16.0923 17.3332 14.2513 17.3332H4.2513C2.41035 17.3332 0.917969 15.8408 0.917969 13.9998V3.99984ZM4.2513 2.33317C3.33083 2.33317 2.58464 3.07936 2.58464 3.99984V7.33317V13.9998C2.58464 14.9203 3.33083 15.6665 4.2513 15.6665H5.91797L5.91797 7.33317H2.58464L2.58464 5.6665L15.918 5.66651V3.99984C15.918 3.07936 15.1718 2.33317 14.2513 2.33317H4.2513ZM7.58464 7.33317V15.6665H14.2513C15.1718 15.6665 15.918 14.9203 15.918 13.9998V7.33317L7.58464 7.33317Z"
    />
  </SvgIcon>
);

export default LayoutIcon;
