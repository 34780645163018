import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const GradientLinkIcon = (props: SvgIconProps) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M12.2516 4.93409L10.0575 6.98008C9.65525 7.35518 9.63304 7.99119 10.0081 8.39343C10.3832 8.79568 11.0192 8.81789 11.4215 8.44279L13.6156 6.39679C14.8223 5.2715 16.7303 5.33813 17.8556 6.54486C18.9809 7.75159 18.9143 9.65962 17.7075 10.7849L15.5135 12.8309C15.1112 13.206 15.089 13.842 15.4641 14.2443C15.8392 14.6465 16.4752 14.6687 16.8775 14.2936L19.0715 12.2476C21.0901 10.3653 21.2006 7.1994 19.3183 5.18086C17.436 3.16233 14.2701 3.05177 12.2516 4.93409ZM9.07936 14.7288C9.45446 15.1311 10.0905 15.1533 10.4927 14.7782L14.8808 10.6862C15.2831 10.3111 15.3053 9.6751 14.9302 9.27285C14.5551 8.87061 13.9191 8.8484 13.5168 9.2235L9.12872 13.3155C8.72647 13.6906 8.70426 14.3266 9.07936 14.7288ZM12.5881 15.5589L10.394 17.6049C9.18727 18.7302 7.27925 18.6636 6.15395 17.4568C5.02865 16.2501 5.09528 14.3421 6.30202 13.2168L8.49608 11.1708C8.89832 10.7957 8.92053 10.1597 8.54543 9.75743C8.17033 9.35519 7.53432 9.33298 7.13208 9.70808L4.93802 11.7541C2.91948 13.6364 2.80893 16.8023 4.69124 18.8208C6.57356 20.8394 9.73947 20.9499 11.758 19.0676L13.9521 17.0216C14.3543 16.6465 14.3765 16.0105 14.0014 15.6083C13.6263 15.206 12.9903 15.1838 12.5881 15.5589Z"
      fill="url(#paint0_linear_9318_84086)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9318_84086"
        x1="2.11233"
        y1="14.3891"
        x2="18.9211"
        y2="14.2524"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.125995" stop-color="#37C556" />
        <stop offset="1" stop-color="#15B3B3" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default GradientLinkIcon;
