import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const UnlistedIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path d="M9.24765 1.93409L7.05359 3.98008C6.65134 4.35518 6.62913 4.99119 7.00423 5.39343C7.37933 5.79568 8.01534 5.81789 8.41758 5.44279L10.6116 3.39679C11.8184 2.2715 13.7264 2.33813 14.8517 3.54486C15.977 4.75159 15.9104 6.65962 14.7036 7.78492L12.5096 9.83091C12.1073 10.206 12.0851 10.842 12.4602 11.2443C12.8353 11.6465 13.4713 11.6687 13.8736 11.2936L16.0676 9.24762C18.0862 7.36531 18.1967 4.1994 16.3144 2.18086C14.4321 0.162327 11.2662 0.0517713 9.24765 1.93409ZM6.07546 11.7288C6.45056 12.1311 7.08657 12.1533 7.48881 11.7782L11.8769 7.68621C12.2792 7.31111 12.3014 6.6751 11.9263 6.27285C11.5512 5.87061 10.9152 5.8484 10.5129 6.2235L6.12481 10.3155C5.72257 10.6906 5.70036 11.3266 6.07546 11.7288ZM9.58416 12.5589L7.3901 14.6049C6.18337 15.7302 4.27534 15.6636 3.15004 14.4568C2.02475 13.2501 2.09138 11.3421 3.29811 10.2168L5.49217 8.17078C5.89441 7.79568 5.91662 7.15968 5.54153 6.75743C5.16643 6.35519 4.53042 6.33298 4.12817 6.70808L1.93411 8.75407C-0.084424 10.6364 -0.19498 13.8023 1.68734 15.8208C3.56965 17.8394 6.73556 17.9499 8.7541 16.0676L10.9482 14.0216C11.3504 13.6465 11.3726 13.0105 10.9975 12.6083C10.6224 12.206 9.98641 12.1838 9.58416 12.5589Z" />
  </SvgIcon>
);

export default UnlistedIcon;
