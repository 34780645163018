import { useCallback } from 'react';

import { captureMessage, withScope } from '@sentry/nextjs';

import { clearBallStrike } from 'api/RDB/baseball';

import useAxios from 'hooks/useAxios';

import type { CommentPageProps } from 'types/comment';
import type { BaseballScoreBookLog, CreateBaseballLogType } from 'types/log';
import type {
  CreateMatchDataType,
  CreatedPlayerInfo,
  MatchImageType,
  MatchLink,
  MatchPlayer,
  MatchPlayerInfo,
  Player,
  PopupMatchData,
  ResultPagesType,
  SearchLogType,
  ServerMatchDetailDataType,
  SeverMatchUpdateDataType,
} from 'types/matchData';
import type { SportType } from 'types/scoreboardData';
import type { ServerMatchData } from 'types/serverMatchData';

const useScoreboardApi = () => {
  const { studioAuthAxiosV1 } = useAxios();

  /**
   * @param channelId 조회할 채널 아이디
   * @description
   * - 특정 채널에 속한 경기 가져오기
   * - 기존 uid, disclosure로 가져오던 방식을 channelId로 변경
   * - 대신 토큰은 항상 전달하여, 백엔드에서 권한체크후 데이터를 가져온다.
   */
  const getChannelMatchList = useCallback(
    async (queries: string) =>
      await studioAuthAxiosV1<ResultPagesType>({
        method: 'GET',
        url: `/scoreboard${queries}`,
      }),
    [studioAuthAxiosV1],
  );

  /**
   * @description
   * - 권한 체크를 위해 토큰은 항상 전달하여, 백엔드에서 권한체크후 데이터를 가져온다.
   */
  const getMatchDetailData = useCallback(
    async (matchId: string) =>
      await studioAuthAxiosV1<ServerMatchDetailDataType>({
        method: 'GET',
        url: `/scoreboard/${matchId}`,
      }),
    [studioAuthAxiosV1],
  );

  const getForYouMatchList = useCallback(
    async (queries: string) =>
      await studioAuthAxiosV1<ResultPagesType>({
        method: 'GET',
        url: `/scoreboard/list/interest${queries}`,
      }),
    [studioAuthAxiosV1],
  );

  const getNewMatchList = useCallback(
    async (queries: string) =>
      await studioAuthAxiosV1<ResultPagesType>({
        method: 'GET',
        url: `/scoreboard/list/new${queries}`,
      }),
    [studioAuthAxiosV1],
  );

  const createMatch = useCallback(
    async (matchData: PopupMatchData): Promise<ServerMatchData> =>
      await studioAuthAxiosV1({
        method: 'POST',
        url: '/scoreboard',
        data: matchData,
      }),
    [studioAuthAxiosV1],
  );

  // TODO createMatch를 createNewMatch로 변경
  const createNewMatch = useCallback(
    async (matchData: CreateMatchDataType): Promise<ServerMatchData> =>
      await studioAuthAxiosV1({
        method: 'POST',
        url: '/scoreboard',
        data: matchData,
      }),
    [studioAuthAxiosV1],
  );

  const createTeam = useCallback(
    async (teamData: any) =>
      await studioAuthAxiosV1({
        method: 'POST',
        url: '/scoreboard/team',
        data: teamData,
      }),
    [studioAuthAxiosV1],
  );

  const createBaseballLog = useCallback(
    async ({
      logType,
      hitterId,
      pitcherId,
      matchUid,
      teamType,
      RDBData,
      scoreDiff,
    }: CreateBaseballLogType) => {
      try {
        await studioAuthAxiosV1({
          method: 'POST',
          url: `/log/baseball/match-logs`,
          data: {
            logType,
            matchUid: matchUid,
            homeTeamScore: Number(RDBData.common.homeTeamScore),
            awayTeamScore: Number(RDBData.common.awayTeamScore),
            inningCount: Number(RDBData.baseBall.inning),
            isFrontInning: RDBData.baseBall.isTop,
            outCount: Number(RDBData.baseBall.out),
            strikeCount: Number(RDBData.baseBall.strike),
            ballCount: Number(RDBData.baseBall.ball),
            isHome: teamType ? teamType === 'home' : null,
            scoreDiff,
            hitterId: hitterId ?? undefined,
            pitcherId: pitcherId ?? undefined,
          },
        });
        if (logType !== 'getScore') clearBallStrike(matchUid, logType);
      } catch (e) {
        withScope((scope) => {
          scope.setTag('match log', 'Baseball');
          scope.setContext('log detail data', {
            logType,
            matchUid,
          });
          captureMessage(`야구 로그 에러 \n 에러 메시지 : ${e}`);
        });
        throw e;
      }
    },
    [studioAuthAxiosV1],
  );

  const createBaseballLogFromScoreBook = useCallback(
    async ({
      logType,
      playerId,
      inningCount,
      matchUid,
      teamType,
    }: BaseballScoreBookLog) => {
      try {
        await studioAuthAxiosV1({
          method: 'POST',
          url: `/log/baseball/match-logs`,
          data: {
            logType,
            hitterId: logType !== 'stealBase' ? playerId : undefined,
            playerId: logType === 'stealBase' ? playerId : undefined,
            inningCount,
            isFrontInning: teamType === 'away',
            isHome: teamType === 'home',
            matchUid,
          },
        });
      } catch (e) {
        withScope((scope) => {
          scope.setTag('match log', 'Baseball scoreBook');
          scope.setContext('log detail data', {
            logType,
            matchUid,
          });
          captureMessage(`야구 기록지 로그 에러 \n 에러 메시지 : ${e}`);
        });
      }
    },

    [studioAuthAxiosV1],
  );

  const updateTeam = useCallback(
    async (
      teamId: number,
      updatedTeamData: {
        teamName?: string;
        sportType?: SportType;
        teamLogoUrl: null | string;
        createPlayerInfo?: Player[];
        updatePlayerInfo?: Player[];
        deletePlayerInfo?: number[] | null;
      },
    ) => {
      try {
        await studioAuthAxiosV1({
          method: 'PATCH',
          url: `/scoreboard/team/${teamId}`,
          data: updatedTeamData,
        });
      } catch (error) {
        captureMessage(`updateTeam 에러 : ${error}`);
        throw error;
      }
    },
    [studioAuthAxiosV1],
  );

  const reportMatch = useCallback(
    async (matchId: string, reportData: any) =>
      await studioAuthAxiosV1({
        method: 'POST',
        url: `/scoreboard/report/match/${matchId}`,
        data: reportData,
      }),
    [studioAuthAxiosV1],
  );

  const updateServerLike = useCallback(
    async (matchId: string) =>
      await studioAuthAxiosV1({
        method: 'PUT',
        url: `/scoreboard/like/${matchId}`,
      }),
    [studioAuthAxiosV1],
  );

  const updateServerMatchData = useCallback(
    async (
      matchId: string,
      updatedData: {
        awayTeamId?: number | null;
        homeTeamId?: number | null;
        connectedAwayTeamId?: number | null;
        connectedHomeTeamId?: number | null;
        updateMatchInfo?: SeverMatchUpdateDataType;
        createPlayerInfo?: MatchPlayer[];
        updatePlayerInfo?: MatchPlayer[];
        deletePlayerInfo?: number[];
        addImageInfo?: MatchImageType[];
        deleteImageInfo?: number[];
        addLinksInfo?: MatchLink[];
        deleteLinksInfo?: number[];
        addLeaderboardIds?: number[];
        deleteLeaderboardIds?: number[];
      },
    ) => {
      try {
        return await studioAuthAxiosV1<{
          matchInfo: ServerMatchDetailDataType;
        }>({
          method: 'PATCH',
          url: `/scoreboard/${matchId}`,
          data: updatedData,
        });
      } catch (e) {
        captureMessage(`updateServerMatchData 에러 : ${e}`);
      }
    },
    [studioAuthAxiosV1],
  );

  // TODO createMatch를 updateNewServerMatchData 변경
  const updateNewServerMatchData = useCallback(
    async (
      matchId: string,
      updatedData: {
        awayTeamId: number | null;
        homeTeamId: number | null;
        connectedAwayTeamId: number | null;
        connectedHomeTeamId: number | null;
        updateMatchInfo: SeverMatchUpdateDataType;
        createPlayerInfo: CreatedPlayerInfo[];
        updatePlayerInfo: MatchPlayerInfo[];
        deletePlayerInfo: number[];
        addImageInfo: MatchImageType[];
        deleteImageInfo: number[];
        addLinksInfo: MatchLink[];
        deleteLinksInfo: number[];
      },
    ) => {
      try {
        return await studioAuthAxiosV1<{
          matchInfo: ServerMatchDetailDataType;
        }>({
          method: 'PATCH',
          url: `/scoreboard/${matchId}`,
          data: updatedData,
        });
      } catch (e) {
        captureMessage(`updateServerMatchData 에러 : ${e}`);
      }
    },
    [studioAuthAxiosV1],
  );

  const deleteMatch = useCallback(
    async (matchId: string) =>
      await studioAuthAxiosV1({
        method: 'DELETE',
        url: `/scoreboard/${matchId}`,
      }),
    [studioAuthAxiosV1],
  );

  const deleteMatches = useCallback(
    async (matchIdList: string[]) =>
      await studioAuthAxiosV1({
        method: 'DELETE',
        url: `/scoreboard/`,
        data: { matchUids: matchIdList },
      }),
    [studioAuthAxiosV1],
  );

  const deleteTeam = useCallback(
    async (teamId: number) =>
      await studioAuthAxiosV1({
        method: 'DELETE',
        url: `/scoreboard/team/${teamId}`,
      }),
    [studioAuthAxiosV1],
  );

  const getAuthComment = useCallback(
    async (matchId: string, limit: number, offset: number) =>
      await studioAuthAxiosV1<CommentPageProps>({
        method: 'GET',
        url: `/scoreboard/comment/${matchId}?limit=${limit}&offset=${offset ?? 0}`,
      }),
    [studioAuthAxiosV1],
  );

  const postComment = useCallback(
    async (matchId: string, comment: string) =>
      await studioAuthAxiosV1({
        method: 'POST',
        url: `/scoreboard/comment/${matchId}`,
        data: {
          comment,
        },
      }),
    [studioAuthAxiosV1],
  );

  const deleteComment = useCallback(
    async (matchId: string, commentId: number) =>
      await studioAuthAxiosV1({
        method: 'DELETE',
        url: `/scoreboard/comment/${matchId}/${commentId}`,
      }),
    [studioAuthAxiosV1],
  );

  const reportComment = useCallback(
    async (commentId: number, reportReason: string) =>
      await studioAuthAxiosV1({
        method: 'POST',
        url: `/scoreboard/report/comment/${commentId}`,
        data: { reportReason },
      }),
    [studioAuthAxiosV1],
  );

  const getSearchKeyword = useCallback(
    async () =>
      await studioAuthAxiosV1<SearchLogType[]>({
        method: 'GET',
        url: '/scoreboard/searchKeyword',
      }),
    [studioAuthAxiosV1],
  );
  const postSearchKeyword = useCallback(
    async (keyword: string) =>
      await studioAuthAxiosV1({
        method: 'POST',
        url: `/scoreboard/searchKeyword/${keyword}`,
      }),
    [studioAuthAxiosV1],
  );

  const deleteSearchKeyword = useCallback(
    async (keywordId: number) =>
      await studioAuthAxiosV1({
        method: 'DELETE',
        url: `/scoreboard/searchKeyword/${keywordId}`,
      }),
    [studioAuthAxiosV1],
  );

  const updateCheerCount = useCallback(
    async (
      matchId: string,
      cheerCount: { homeCheerCount: number; awayCheerCount: number },
    ) =>
      await studioAuthAxiosV1({
        method: 'PATCH',
        url: `/scoreboard/cheer/${matchId}`,
        data: { ...cheerCount },
      }),
    [studioAuthAxiosV1],
  );

  const authIncreaseMatchViewCount = useCallback(
    async (matchId: string) =>
      await studioAuthAxiosV1({
        method: 'PATCH',
        url: `/scoreboard/views/${matchId}`,
      }),
    [studioAuthAxiosV1],
  );

  return {
    createMatch,
    createNewMatch,
    getForYouMatchList,
    getNewMatchList,
    getChannelMatchList,
    getMatchDetailData,
    createTeam,
    createBaseballLog,
    createBaseballLogFromScoreBook,
    reportMatch,
    updateServerLike,
    updateServerMatchData,
    updateNewServerMatchData,
    updateTeam,
    deleteMatch,
    deleteTeam,
    getAuthComment,
    postComment,
    deleteComment,
    reportComment,
    getSearchKeyword,
    postSearchKeyword,
    deleteSearchKeyword,
    updateCheerCount,
    deleteMatches,
    authIncreaseMatchViewCount,
  };
};

export default useScoreboardApi;
