import { useTheme } from '@mui/material';

const useCommonSx = () => {
  const {
    palette: { mode },
  } = useTheme();

  const scrollSx = {
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'deprecatedGray.70',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: mode === 'dark' ? 'deprecatedGray.20' : 'white',
    },
  };

  const boldScrollSx = {
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray.200',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
  };

  const lineSx = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflowWrap: 'anywhere',
    wordBreak: 'break-all',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  };

  const oneLineSx = {
    ...lineSx,
    WebkitLineClamp: 1,
  };

  const twoLineSx = {
    ...lineSx,
    WebkitLineClamp: 2,
  };

  const threeLineSx = {
    ...lineSx,
    WebkitLineClamp: 3,
  };
  const fourLineSx = {
    ...lineSx,
    WebkitLineClamp: 4,
  };

  const isRequiredSx = {
    '&:after': {
      content: '"*"',
      color: 'status.critical',
      ml: '2px',
    },
  };

  return {
    isRequiredSx,
    lineSx,
    oneLineSx,
    scrollSx,
    boldScrollSx,
    threeLineSx,
    twoLineSx,
    fourLineSx,
  };
};

export default useCommonSx;
