const convertQueryToString = (
  query: string | string[] | null | undefined,
): string => {
  if (query === null || query === undefined) {
    return '';
  }
  if (Array.isArray(query)) {
    return query.join(',');
  }
  return query;
};

export default convertQueryToString;
