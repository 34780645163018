/**
 * @description 페이지 경로에서 어떤 콘텐츠인지 리턴한다.
 *
 * @example 'http://localhost:3000/ko/channel/@develop?tab=events' -> 'channel'
 *
 * @example 'http://localhost:3000/ko/event' => 'event'
 *
 * @example 'http://localhost:3000/ko' => undefined
 */
const getContentGroupForGTM = (route: string) => {
  const queryList = route.split('/');

  if (queryList.length < 2 || queryList[1] === '') return undefined;
  return queryList[1];
};

export default getContentGroupForGTM;
