import convertQueryToString from 'utils/convertQueryToString';

import getPageNameForGTM from './getPageNameForGTM';

/**
 * @description 페이지 경로와 쿼리를 받아서 GTM에 contentId로 사용할 값을 반환하는 함수
 *
 * @example 'http://localhost:3000/ko/channel/@develop?tab=events' -> '@develop'
 * - 이러한 경로에서 이함수는 locale, 쿼리파라미터 등에 영향받지않고 '@develop'를 반환한다.
 *
 * @example 'http://localhost:3000/ko/event' => undefined
 * - 만약 contentId가 없는 페이지라면 undefined를 반환한다.
 */
const getContentIdForGTM = (
  route: string,
  query: {
    [key: string]: string | string[] | undefined;
  },
) => {
  const hasContentId = getPageNameForGTM(route).includes('Detail');
  if (!hasContentId) return undefined;

  const contentIdName = route.split('[')[1].split(']')[0];
  return convertQueryToString(query[contentIdName]);
};

export default getContentIdForGTM;
